import { useEffect } from 'react'
import { EnvEnum } from '../../../types/enums.ts'

export function ExternalScripts({
  gtmTrackingId,
}: {
  gtmTrackingId: string | undefined
}) {
  const createGtmScript = () => {
    const gtmScript = document.createElement('script')

    gtmScript.innerHTML = `
        (function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src =
              'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${gtmTrackingId}');`

    document.head.appendChild(gtmScript)
    return gtmScript
  }

  const createGTagScript = () => {
    // Create the script element for loading the gtag.js library
    const gtagScript = document.createElement('script')
    gtagScript.async = true
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=AW-472216438'

    // Create the inline script for initializing the Google Tag
    const initGtagScript = document.createElement('script')
    initGtagScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'AW-472216438');
  `

    // Append the scripts to the document head
    document.head.appendChild(gtagScript)
    document.head.appendChild(initGtagScript)

    return [gtagScript, initGtagScript] // Return both script elements
  }

  const createFbPixelScript = () => {
    const fbPixelScript = document.createElement('script')

    fbPixelScript.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1718546662000853');
        fbq('track', 'PageView');
      `

    document.head.appendChild(fbPixelScript)
    return fbPixelScript
  }

  const createConnectifPixel = () => {
    const connectifPixel = document.createElement('script')
    connectifPixel.id =
      '__cn_generic_script__d0e3f39d-4017-437a-b3a5-5ddaa0fea007'
    connectifPixel.innerHTML = `
!function(e){function t(){if(!e.querySelector("#__cn_client_script_d0e3f39d-4017-437a-b3a5-5ddaa0fea007")){var t=e.createElement("script");t.setAttribute("src","https://cdn.connectif.cloud/eu2/client-script/d0e3f39d-4017-437a-b3a5-5ddaa0fea007"),e.body.appendChild(t)}}"complete"===e.readyState||"interactive"===e.readyState?t():e.addEventListener("DOMContentLoaded",t)}(document);
`
    document.body.prepend(connectifPixel)
    return connectifPixel
  }

  useEffect(() => {
    if (ENV.MODE === EnvEnum.PRODUCTION || ENV.MODE === EnvEnum.STAGING) {
      const gtmScript = createGtmScript()
      const [gtagScript, initGtagScript] = createGTagScript()
      const fbPixelScript = createFbPixelScript()
      const connectifPixel = createConnectifPixel()

      return () => {
        document.head.removeChild(gtmScript)
        document.head.removeChild(gtagScript)
        document.head.removeChild(initGtagScript)
        document.head.removeChild(fbPixelScript)
        document.body.removeChild(connectifPixel)
      }
    }
  }, [])

  return ENV.MODE !== EnvEnum.PRODUCTION || !gtmTrackingId ? null : (
    <>
      {/* GOOGLE TAG MANAGER NOSCRIPT */}
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${gtmTrackingId}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      {/* Meta Pixel Code */}
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=1718546662000853&ev=PageView&noscript=1"
        />
      </noscript>
    </>
  )
}
